import React, { useEffect, useState } from 'react';

//material-ui
import { makeStyles } from 'tss-react/mui';
import { Button, CardContent, Grid, IconButton, Modal, TextField, FormControlLabel, Checkbox, FormGroup, Typography } from '@mui/material';

import { Close } from '@mui/icons-material';
import MainCard from '../../src/ui-component/cards/MainCard';
import { getSession, signOut } from 'next-auth/react';

// modal position
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

// style constant
const useStyles = makeStyles()((theme) => {return {
    paper: {
        position: 'absolute',
        width: 450,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: 280
        }
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}});

// modal content
const Body = React.forwardRef(({ modalStyle, classes, handleClose }, ref) => {
    const [timer, setTimer] = useState(30);

    useEffect(()=>{
        if(timer === 0){
            handleLogout()
        } else {
            const myinterval = setInterval(()=>{
                if(timer>0){
                    setTimer(timer=>timer-1)
                } else {
                    handleLogout()
                }
            },1000)
            return () => clearInterval(myinterval)
        }
        
    },[timer])

    const handleLogout = ()=>{
        signOut()
    }
    return (
        <div ref={ref} tabIndex={-1}>
            <MainCard
                style={modalStyle}
                className={classes.paper}
                title="Session Timeout"
                content={false}
                secondary={
                    <IconButton onClick={handleClose}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography>
                                You are being timed out due to inactivity. Please choose to stay signed in or to log out. Otherwise, you will logged off automatically.  
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Button variant="clear"
                                onClick={() => {
                                    handleLogout();
                                }}>Log Out </Button>
                            <Button variant="contained"
                                onClick={() => {
                                    getSession();
                                    handleClose();
                                }}>Stay Logged In ({timer}) </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </MainCard>
        </div>
    );
});

//-----------------------||  MODAL ||-----------------------//

export default function SessionTimeout(props) {
    const { classes } = useStyles();

    const [modalStyle] = useState(getModalStyle);
    
    return (
        <Modal open={props.open} onClose={props.handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <Body
                classes={classes}
                modalStyle={modalStyle}
                handleClose={props.handleClose}
            />
        </Modal>
    );
}
