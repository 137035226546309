import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {cacheExchange, fetchExchange } from '@urql/core';
import { withUrqlClient } from 'next-urql';
import NextApp from 'next/app';
import Head from 'next/head';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Script from 'next/script'
import { ThemeProvider } from '@mui/material/styles';

import Router from 'next/router';
import { ToastContainer } from 'react-toastify';
import { SessionProvider } from 'next-auth/react';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/custom.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RecoilRoot } from 'recoil';

// third-party
import theme from '../styles/themes';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from '../styles/createEmotionCache';
import AuthLayout from '../layout/AuthLayout';
import Loader from '../components/Loader';
import { QueryClient, QueryClientProvider } from 'react-query';
// import posthog from 'posthog-js';

// the URL to /api/graphql
export const GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ||
  `http://localhost:4000/api/graphql`;

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App = props => {
  const customization = {
    fontFamily: `"Inter","Roboto","Helvetica","Arial", sans-serif`
  };
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { session } = pageProps;
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    const pageTransitionContainer = document.getElementById('page-transition');
    if (!pageTransitionContainer) {
      console.error('page transition container not found');
      return;
    }

    const handleRouteChangeStart = () => {
      localStorage.setItem("time", Date.now())
      document.body.classList.add('body-page-transition');
      ReactDOM.render(<Loader />, pageTransitionContainer);
    };

    const handleRouteChangeComplete = () => {
      const time = localStorage.getItem("time")
      console.log("it took", (Date.now() - parseInt(time)), " milliseconds")
      if (!pageTransitionContainer) {
        console.error('page transition container not found');
        return;
      }

      ReactDOM.unmountComponentAtNode(pageTransitionContainer);
      document.body.classList.remove('body-page-transition');
    };

    const handleRouteChangeError = () => {
      if (!pageTransitionContainer) {
        console.error('page transition container not found');
        return;
      }

      ReactDOM.unmountComponentAtNode(pageTransitionContainer);
      document.body.classList.remove('body-page-transition');
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);
    Router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
      Router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, []);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  // React.useEffect(() => {
  //   if (process.env.NEXT_PUBLIC_POSTHOG_DISABLED === 'false') {
  //     posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
  //       api_host: 'https://app.posthog.com',
  //       loaded: posthog => { }
  //     });
  //   }
  // }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Security-Policy" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#2296f3" />
        <meta name="title" content="Vindeca Health" />
        <meta name="description" content="Reducing Treatment Costs With Evidence-Based Medication Strategy" />
        <meta
          name="keywords"
          content="vindeca, health, vindeca health, health data, save money"
        />
        <meta name="author" content="VindecaHealth" />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.vindecahealth.com/" />
        <meta property="og:site_name" content="Vindeca Health" />

        <meta property="og:title" content="Vindeca health" />
        <meta
          property="og:description"
          content="Reducing Treatment Costs With Evidence-Based Medication Strategy"
        />
        <meta property="og:image" content="/images/logo.png" />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://twitter.com/VindecaHealth" />
        <meta property="twitter:title" content="Vindeca Health" />
        <meta property="twitter:image" content="/images/logo.png" />
      </Head>
      <div id="page-transition"></div>
      <ToastContainer />
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme(customization)}>
              <StyledEngineProvider injectFirst>
                <CssBaseline />
                <SessionProvider session={session} refetchInterval={5 * 60}>
                  {/* <AuthLayout>  */}
                  <DndProvider backend={HTML5Backend}>
                    <div style={{ visibility: mounted ? 'visible' : 'hidden' }}>
                      <Script src="https://static.zdassets.com/ekr/snippet.js?key=6332e863-ed13-41aa-a113-599136c0c651" id="ze-snippet" />
                      <Script src="https://vindecahealth.statuspage.io/embed/script.js" />
                      {Component.auth ? (
                        <>
                          <AuthLayout>
                            {mounted ? (
                              <div
                                style={{
                                  visibility: mounted ? 'visible' : 'hidden'
                                }}
                              >
                                <Component {...pageProps} />
                              </div>
                            ) : (
                              <Loader />
                            )}
                          </AuthLayout>
                        </>
                      ) : (
                        <Component {...pageProps} />
                      )}

                      {/* <Component {...pageProps} /> */}
                    </div>
                  </DndProvider>
                  {/* </AuthLayout>  */}
                </SessionProvider>
              </StyledEngineProvider>
            </ThemeProvider>
          </CacheProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
};

App.getInitialProps = async ctx => {
  const appProps = await NextApp.getInitialProps(ctx);
  return { ...appProps };
};

export default withUrqlClient((ssrExchange, _ctx) => ({
  url: GRAPHQL_ENDPOINT,
  exchanges: [cacheExchange, ssrExchange, fetchExchange]
}))(
  // @ts-ignore
  App
);
