import { getSession, useSession } from 'next-auth/react';
import { useRouter, withRouter } from 'next/router';
import React, { useState } from 'react';
import SessionTimeout from '../../components/auth/SessionTimeout';
import Loader from '../../components/Loader';


const AuthLayout = ({ children }) => {
  const router = useRouter()
  const { status, data } = useSession({ required: true })
  const [openTimeout, setOpenTimeout] = useState(false)

  const handleClose = ()=>{
    setOpenTimeout(false)
  }

  const handleOnIdle = event => {
    setOpenTimeout(true)
  }



  if (status === "loading") {
    return <div><Loader /></div>
  }

  return <>
    {children}
    <SessionTimeout open={openTimeout} handleClose={handleClose} />
  </>;
};

export default withRouter(AuthLayout);
